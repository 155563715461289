var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: { title: "Impersonate User", icon: "mdi-account-convert" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "accent",
                    loading: _vm.loading,
                    disabled: _vm.selectedUser === null
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.impersonate($event)
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-account-convert")
                  ]),
                  _vm._v(" Impersonate ")
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "psi-form-element",
        { attrs: { label: "Find User", icon: "mdi-account" } },
        [
          _c("psi-form-autocomplete-field", {
            attrs: {
              items: _vm.users,
              attrs: _vm.attrs,
              icon: "mdi-account",
              placeholder: "Search by name or email",
              id: "users-list",
              name: "people",
              value: "selectedUser",
              "item-text": "name",
              "item-value": "id",
              "return-object": ""
            },
            on: { input: _vm.userInput, "search-input": _vm.searchInput }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }