<template>
    <psi-dialog
        title="Impersonate User"
        @close="$emit('close')"
        icon="mdi-account-convert"
    >
        <psi-form-element label="Find User" icon="mdi-account">
            <psi-form-autocomplete-field
                :items="users"
                :attrs="attrs"
                icon="mdi-account"
                placeholder="Search by name or email"
                id="users-list"
                name="people"
                value="selectedUser"
                item-text="name"
                item-value="id"
                return-object
                @input="userInput"
                @search-input="searchInput"
            >
            </psi-form-autocomplete-field>
        </psi-form-element>
        <template v-slot:actions>
            <v-btn class="mr-4" @click="$emit('close')">Cancel</v-btn>
            <v-btn
                color="accent"
                @click.stop="impersonate"
                :loading="loading"
                :disabled="selectedUser === null"
            >
                <v-icon class="mr-2">mdi-account-convert</v-icon>
                Impersonate
            </v-btn>
        </template>
    </psi-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            selectedUser: null,
        };
    },
    computed: {
        ...mapGetters("Users", ["users", "loading"]),
        attrs() {
            return {
                "no-filter": true,
                loading: this.loading,
                clearable: true,
            };
        },
    },
    methods: {
        ...mapActions("Users", ["searchUsers", "impersonateUser"]),
        userInput(data) {
            this.selectedUser = data;
            if (this.selectedUser === null) {
                this.searchUsers({});
            }
        },
        searchInput(event) {
            if (event && event.length > 2 && this.selectedUser === null) {
                this.searchUsers({ search: event });
            }
        },
        async impersonate() {
            const user = await this.impersonateUser({
                user_id: this.selectedUser.id,
                impersonate: true,
            });
            if (user.id === this.selectedUser.id) {
                this.$emit("close");
                window.location.href = user.homeUrl.link.href;
            }
        },
    },
    created() {},
};
</script>

<style lang="scss" scoped>
</style>